export const ACCESS_TOKEN = "Access_Token";
export const REFRESH_TOKEN = "Refresh_Token";
export const ORG_ID = "Org_Id";
export const APP_ID = "App_Id";
export const CACHED_IMAGE_URLS = "Cached_Image_Urls"
export const GLOBAL_CONFIG = "Global_Config";
export const GLOBAL_CONFIG_KEYS = {
    SALES_INVOICE_PREFIX: "sales invoice prefix",
    PURCHASE_INVOICE_PREFIX: "purchase invoice prefix",
    DATE_FORMAT: "date format",
    PARTIAL_EMI_PAYMENT_DATE_LIMIT: "partial emi payment date limit",
    SALES_INVOICE_TYPE: "sales invoice type",
    SALES_TERMS_AND_CONDITIONS: "sales terms and conditions",
    PURCHASE_TERMS_AND_CONDITIONS: "purchase terms and conditions",
    CREDIT_EMI_SALES: "credit emi sales",
    ALLOW_UNIT_CONVERSION_ON_SALES: "allow unit conversion on sales",
}
export const RegexPatterns = {
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    username: /^[a-zA-Z0-9_]{3,20}$/,
    password: /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/,
    phone: /^\d{3}-?\d{3}-?\d{4}$/,
    url: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
    date: /^\d{4}-\d{2}-\d{2}$/,
    creditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})$/,
    ipv4: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
    hexColor: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    zipCode: /^\d{5}(?:-\d{4})?$/,
    nameWithUnderscore: /^[a-zA-Z0-9_]*$/,
    lowerCaseWithUnderscore: /^[a-z_]*$/,
    nameWithoutDigit: /^\D*$/,
    // namewithSpecChars: /^[a-zA-Z0-9!@#$%^&*)(+=._-\s]*$/,
    isoString: /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z$/,
    dateWithTimezoneString: /^((19|20)[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([01][0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])([+|-]([01][0-9]|[2][0-3])[:]([0-5][0-9])){0,1}$/,
    capitalize: /[A-Z][a-zA-Z]*/,
    otp: /^[A-Za-z0-9]{6}$/,
    panNumber: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
    gstNumber: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
}
export const FilterTypes = {
    EQUAL_TO: "equal_to",
    NOT_EQUAL_TO: "not_equal_to",
    BETWEEN: "between",
    LESS_THAN: "less_than",
    LESS_THAN_OR_EQUAL_TO: "less_than_or_equal_to",
    GREATER_THAN: "greater_than",
    GREATER_THAN_OR_EQUAL_TO: "greater_than_or_equal_to",
    SORT_BY: "sort_by",
    LIKE: "like",
    LIMIT: "limit",
    LESS_THAN_BETWEEN_COLUMNS: "less_than_between_columns",
    GREATER_THAN_BETWEEN_COLUMNS: "greater_than_between_columns",
    COUNT_ALL_ROWS: "count_all_rows",
};
export const PaymentStatus = {
    PAID: "paid",
    UNPAID: "unpaid",
    PARTIALLY_PAID: "partially paid"
}
export const Purchase = 'purchase';
export const Sales = 'sales';
export const ADMIN = 'admin';
export const PAGINATION_MODEL_DEFAULT = { page: 0, pageSize: 10 };
export const DEBOUNCE_DELAY = 500;
export const ROLES = {
    ADMIN: "admin",
    SALES_EXECUTIVE: "sales_executive",
    SALES_MANAGER: "sales_manager",
    DELIVERY_EXECUTIVE: "delivery_executive",
    BUSINESS_DEVELOPMENT_MANAGER: "business_development_manager",
    BILLER: "billing_coordinator",
    ASSEMBLER: "assembler_and_packer",
    OPERATIONS_MANAGER: "operations_manager",
    PURCHASE_EXECUTIVE: "purchase_manager",
    PURCHASE_COORDINATOR: "purchase_coordinator",
    CASHIER: "cashier"
}

export const deliveryTypeData = [
    {
        label: "Self Pickup",
        value: "self_pickup"
    },
    {
        label: "Staff Pickup",
        value: "staff_pickup"
    },
    {
        label: "Delivery Partner Pickup",
        value: "delivery_partner_pickup"
    }
]