import dayjs from "dayjs";
import { ACCESS_TOKEN, GLOBAL_CONFIG, GLOBAL_CONFIG_KEYS } from "../constant/constant";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { LocalStorageUtils } from "./local-storage-utils";
import { isMobile, osName } from "mobile-device-detect";
import lodash from "lodash";
import { toast } from "react-toastify";

export const formatDate = (date: any, format = "") => {
  return dayjs(date).format(format || JSON.parse(LocalStorageUtils.getStorage(GLOBAL_CONFIG) as string)?.[GLOBAL_CONFIG_KEYS.DATE_FORMAT]);
}

export const isAuth = () => {
  if (!LocalStorageUtils.getStorage(ACCESS_TOKEN)) return false;
  return true;
}

export const zodPatternValidation = (z: any, pattern: RegExp, message: string) => {
  return z.refine((value: any) => {
    if (value) return pattern.test(value);
    else return true;
  }, message);
}

const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

const convert: any = (num: number) => {
  if (num === 0) {
    return '';
  } else if (num < 10) {
    return units[num];
  } else if (num === 10) {
    return tens[1]
  } else if (num < 20) {
    return teens[num - 11];
  } else if (num < 100) {
    return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
  } else {
    return units[Math.floor(num / 100)] + ' Hundred ' + convert(num % 100);
  }
};

export const convertToIndianWords: any = (num: number) => {
  if (num === 0) {
    return 'Zero';
  } else if (num >= 10000000) {
    const remainder = num % 10000000;
    const crorePart = Math.floor(num / 10000000);
    const crore = convert(crorePart) + (crorePart > 1 ? ' Crores ' : ' Crore ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? crore + rest : crore;
  } else if (num >= 100000) {
    const remainder = num % 100000;
    const lakhPart = Math.floor(num / 100000);
    const lakh = convert(lakhPart) + (lakhPart > 1 ? ' Lakhs ' : ' Lakh ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? lakh + rest : lakh;
  } else if (num >= 1000) {
    const remainder = num % 1000;
    const thousandPart = Math.floor(num / 1000);
    const thousand = convert(thousandPart) + ' Thousand ';
    const rest = convert(remainder);
    return rest !== 'Zero' ? thousand + rest : thousand;
  } else {
    return convert(num);
  }
};

export const DownloadCSV = (rows: any, fileName: any) => {
  const csv = Papa.unparse(rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

export const formatNumber = (numberToBeFormatted: number, formatType = 'en-IN') => {
  return new Intl.NumberFormat(formatType).format(numberToBeFormatted);
}

export const readFileAsDataURL = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    return reader.readAsDataURL(file);
  });
};

export const getPlatform = () => {
  if (isMobile) {
    if (osName.toLowerCase() === "ios" || osName.toLowerCase() === "mac os") {
      return "ios";
    }
    else {
      return "android";
    }
  }
  else {
    return "web";
  }
}

export const getLocation = (setLocationString: any, showToast: boolean = false, setErrorMessage?: any) => {
  if(setErrorMessage) setErrorMessage("");
  navigator.geolocation.getCurrentPosition((position) => {
    if (position.coords.latitude && position.coords.longitude) {
      setLocationString(`POINT(${position.coords.longitude} ${position.coords.latitude})`);
      if (showToast) {
        toast.success("Current Location Fetched", {
          position: 'top-center',
        });
      }
    }
  }, (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        if(setErrorMessage) setErrorMessage("User denied the request for Geolocation.")
        break;
      case error.POSITION_UNAVAILABLE:
        if(setErrorMessage) setErrorMessage("Location information is unavailable.")
        break;
      case error.TIMEOUT:
        if(setErrorMessage) setErrorMessage("The request to get user location timed out.")
        break;
      default:
        if(setErrorMessage) setErrorMessage("An unknown error occurred.")
    }
  });
}

export const filterArrayByArray = ((mainArray: any, excludeArray: any, key = "id") => {
  return lodash.differenceBy(mainArray, excludeArray, key);
});

export const getCommonObjectsBetweenArrays = ((array1: any, array2: any, key = "id") => {
  return lodash.intersectionBy(array1, array2, key);
});

export const arrayToObject = ((array: any, key = "id") => {
  return lodash.keyBy(array, key);
});

export function generateOtp(length?: number): string {
  // Exclude all characters that are hard to tell apart - 0, O, o, I, l, 1
  const allowedChars = "23456789";
  const otpLength = length || 6;
  const otp = [];
  for (let i = 0; i < otpLength; i++) {
    otp.push(allowedChars[Math.floor(Math.random() * allowedChars.length)]);
  }
  return otp.join('');
}